import './style.css';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Autocomplete, TextField } from '@procurenetworks/procure-component-library';
import clsx from 'clsx';
import { uniqBy } from 'lodash';

import { useStyle } from '../../modules/inventory/components/useStyle';
import { AsyncMultiTableFilterOption, AsyncMultiTableFilterProps } from './types';
import useDebounce from '../../hooks/useDebounce';
import useOutSideClick from '../../hooks/useOutSideClick/useOutSideClick';
import { nanoid } from 'nanoid';

const getOptionLabel = (option: AsyncMultiTableFilterOption) => option.label;
const getOptionValue = (option: AsyncMultiTableFilterOption) => option.value;

const EMPTY_ARRAY: never[] = [];

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> { }

const ListBox = forwardRef(function ListBoxBase(
  props: ListBoxProps,
  ref: ForwardedRef<HTMLUListElement>,
) {
  const { children, ...rest } = props;

  const innerRef = useRef<HTMLUListElement>(null);

  useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current);

  return (
    <ul {...rest} ref={innerRef} role="list-box">
      {children}
    </ul>
  );
});

type NullableUlElement = HTMLUListElement | null;

const AsyncMultiTableFilter = (props: AsyncMultiTableFilterProps) => {
  const {
    queryVariables,
    useSelectedValue,
    useSelectQuery,
    placeholder = '',
    value: valueProp,
    onBlur,
    onChange,
    disabled,
    autoFocus,
    limitTags = 3,
    paginated,
    label,
    testId,
    name,
    onClick,
    className,
    size,
  } = props;

  const classes = useStyle();
  const inputRef = useRef<any>();

  const value = valueProp || EMPTY_ARRAY;
  const [open, setOpen] = useState<boolean>(false);
  const [menuId] = useState(nanoid().toString());
  const [menuRef, setMenuRef] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState<AsyncMultiTableFilterOption[]>([]);
  const [activePage, setActivePage] = useState(0);
  const isQueryVariablesChange = JSON.stringify(queryVariables);
  useEffect(() => {
    setActivePage(0);
  }, [isQueryVariablesChange]);

  const { isLoading, options, onNextPage } = useSelectQuery({
    inputValue,
    activePage,
    ...queryVariables,
  });

  const { isDisabled, selectedValues } = useSelectedValue({
    value,
    pause: !value.length || Boolean(value.length && selectedValue.length),
    options: options,
    ...queryVariables,
    isMultiple: true,
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    const getMenuRef = () => ({
      current: document?.getElementById(menuId),
    });

    setTimeout(() => {
      const menuRef = getMenuRef();
      setMenuRef(menuRef);
    }, 100);
  }, [open]);

  const onOutSideClick = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open, setOpen]);

  useOutSideClick([menuRef, inputRef], onOutSideClick);

  useEffect(() => {
    if (selectedValues) {
      setSelectedValue(selectedValues);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (value.length === 0) {
      setSelectedValue([]);
    }
  }, [value]);

  const onScroll = useCallback(
    (event: any) => {
      const listBoxNode: HTMLElement | null = event.currentTarget;

      if (!listBoxNode) {
        return;
      }

      const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
      if (listBoxNode.scrollHeight - position <= 10 && onNextPage && !isLoading) {
        onNextPage();
        setActivePage(activePage + 1);
      }
    },
    [onNextPage, isLoading, setActivePage, activePage],
  );

  const onValueChange = useCallback(
    (event: React.SyntheticEvent, options: AsyncMultiTableFilterOption[]) => {
      event.preventDefault();
      const _options = uniqBy(options, (role) => role.value);
      setSelectedValue(_options as AsyncMultiTableFilterOption[]);
      onChange?.(
        options.map(({ value }) => value),
        options as AsyncMultiTableFilterOption[],
      );
    },
    [onChange],
  );

  const onInputValueChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    if (reason !== 'reset') {
      setInputValue(value);
    }
  };

  const handleOnBlur = useCallback(
    (event: any) => {
      setInputValue('');
      onBlur?.(event);
    },
    [onBlur, setInputValue],
  );

  return (
    <div ref={inputRef} className="w-[100%] async-multi-table-filter-wrapper">
      <Autocomplete
        checkBoxOptions
        disableCloseOnSelect
        multiple
        noChips
        slotProps={{
          listbox: paginated
            ? {
              onScroll: onScroll,
              role: 'listbox',
              id: menuId,
              className: 'async-table-filter-listbox',
            }
            : {
              role: 'listbox',
              id: menuId,
              className: 'async-table-filter-listbox',
            },
        }}
        className={clsx(classes.root, 'table-multi-table-filter', className)}
        disabled={isDisabled || disabled}
        getOptionLabel={getOptionLabel}
        inputValue={inputValue || ''}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value;
        }}
        label={''}
        loading={isLoading}
        open={open}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            inputProps={{
              ...params.inputProps,
              'data-testid': testId,
              className: `${params.inputProps.className || ''} ${inputValue ? 'bg-white' : ''}`,
            }}
            label={label}
            name={name || ''}
            placeholder={!selectedValue?.length ? placeholder : ''}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        )}
        size={size}
        sx={{
          '&.MuiAutocomplete-root > div': {
            display: 'flex',
          },
          '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
            position: 'absolute !important',
            marginLeft: 'auto',
            right: '1px !important'
          },
          '&.MuiAutocomplete-root .MuiOutlinedInput-input': {
            width: selectedValue.length > 0 ? '0px' : 'calc(100% - 32px) !important',
            minWidth: '20px',
            display: selectedValue.length > 0 ? 'none' : 'block',
          },
        }}
        value={selectedValue}
        onBlur={handleOnBlur}
        onChange={onValueChange}
        onClose={(event, reason) => {
          setInputValue('');
          setOpen(false);
        }}
        onInputChange={onInputValueChange}
        onOpen={() => {
          setOpen(true);
        }}
        fullWidth={true}
        limitTags={1}
        renderTags={(values) =>
          values.map(
            ({ label }, index) => {
              return (
                <span style={{width: values.length < 2 ? 'calc(100% - 0px)' : 'calc(100% - 32px)'}} className="tagSpan" onClick={() => setOpen(true)}>{index < 1 && label + (index < values.length - 1 ? " " : "")}</span>
              )
            }
          )
        }
      />
    </div>
  );
};

export default AsyncMultiTableFilter;
